





































































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import showProList from "@/components/showProList.vue";
import { Sticky, Search, Image, Row, NavBar, List, Empty } from "vant";
@Component({
  components: {
    showProList,
    [Sticky.name]: Sticky,
    [Search.name]: Search,
    [Image.name]: Image,
    [Row.name]: Row,
    [NavBar.name]: NavBar,
    [List.name]: List,
    [Empty.name]: Empty,
  },
})
export default class CouponUseItem extends Mixins(Mixin) {
  public couponId = -1;
  public couponType = "";
  public proList: Array<good.RecommendProductDto> = [];
  public keyword = "";
  public page = {
    pageNum: 1,
    pageSize: 10,
  };
  public list = {
    loading: false,
    finished: false,
  };
  created(): void {
    this.couponId = Number(this.$route.query.couponId);
    this.couponType = String(this.$route.query.couponType);
    window.document.title = this.couponType;
    this.getProductList(true);
  }
  onSearch() {
    this.getProductList(true);
  }
  getProductList(first: boolean): void {
    if (first) {
      this.page.pageNum = 1;
    } else {
      this.page.pageNum++;
    }
    this.$api.goodApi.coupon.getCouponProducts(
      {
        couponId: String(this.couponId),
        keyword: this.keyword,
        page: this.page.pageNum,
        size: this.page.pageSize,
      },
      ({ data }) => {
        if (this.page.pageNum === 1) {
          this.proList = data.records!;
        } else {
          this.proList = this.proList.concat(data.records!);
        }
        this.list.loading = false;
        this.list.finished = data.current! >= data.pages!;
        console.log(this.list.finished);
      }
    );
  }
  goProDetail(item: good.RecommendProductDto): void {
    let channel = this.ChannelModule.channel;
    let theaterId = item.theaterId ? String(item.theaterId) : "40";
    this.getViewShow(
      channel,
      theaterId,
      item.tour,
      item.productId,
      item.tourId,
      item.plat
    );
  }
  onClickLeft(): void {
    this.jsBridge("back", "");
  }
}
