

















































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";

import {
  Col,
  Row,
  NavBar,
  Icon,
  Button,
  Image,
  Swipe,
  SwipeItem,
  Tabs,
  Tab,
  Popup,
  Stepper,
  Dialog,
  Checkbox,
  Field,
} from "vant";
import Ticketltem from "@/components/Tickets/Ticketltem.vue";
import CouponUseItem from "./CouponUseItem.vue";
import CouponDetail from "./Detail.vue";
import { CouponsDetailMessageRes } from "@/shims.decorator";
@Component({
  components: {
    Mixin,
    CouponDetail,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Popup.name]: Popup,
    [Checkbox.name]: Checkbox,
    [Stepper.name]: Stepper,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
    Ticketltem,
    CouponUseItem,
  },
})
export default class CouponItem extends Mixins(Mixin) {
  @Prop({ default: [], type: Array })
  public couponList!: Array<CouponsDetailMessageRes>;
  @Prop({ default: "", type: String }) public text!: string;
  public couponType = "";
  public couponId = 0;
  public showDetail = false;

  /*
   * 处理按钮
   */
  dealName(status?: number): {
    status: number;
    name: string;
  } {
    let data = {
      status: 1,
      name: "",
    };
    if (status === 2) {
      data.status = 1;
      data.name = "去使用";
    } else if (status === 3) {
      data.status = 2;
      data.name = "已使用";
    } else if (status === 5 || status === 4) {
      data.status = 2;
      data.name = "已过期";
    }

    return data;
  }

  /*
   * 处理价格
   */
  dealPrice(price?: number): string {
    return price ? `${price}` : "";
  }

  /*
   * 订单金额提示
   */
  dealAmount(type?: number, Amount?: number): string {
    let name = "";
    if (type === 1) {
      name = "不限金额";
    } else {
      name = `满${Amount}可用`;
    }
    return name;
  }

  /*
   * 处理日期
   */
  dealDate(
    type?: number,
    startTimeStr?: string,
    startTime?: string,
    endTime?: string,
    effectiveDays?: number
  ): string {
    let timer = "";
    let nowTime = new Date().getTime();
    if (type === 1) {
      if (startTime && endTime) {
        if (Number(nowTime) > Number(startTimeStr)) {
          timer = `有效期至${endTime}`;
        } else {
          timer = `有效期${startTime}至${endTime}`;
        }
      } else {
        timer = "";
      }
    } else {
      if (effectiveDays) {
        timer = `${effectiveDays}天后过期`;
      } else {
        timer = "";
      }
    }

    return timer;
  }

  /*
   * 点击领取货使用
   */

  onClick(item: CouponsDetailMessageRes): void {
    if (item.status === 2) {
      this.goUse(item);
    }
  }
  goUse(item: CouponsDetailMessageRes): void {
    this.couponType = this.getCouponType(
      item.orderAmountType!,
      item.orderAmount!,
      item.batchClass!,
      item.parValue!
    );
    this.$router.push(
      `/coupons-use?couponId=${item.couponId}&couponType=${this.couponType}`
    );
  }
  getCouponType(
    orderAmountType: number,
    orderAmount: number,
    batchClass: string,
    parValue: number
  ): string {
    let text = "";
    if (Number(batchClass) === 6) {
      if (orderAmountType === 1) {
        text = `${parValue}折优惠券`;
      } else {
        text = `满${orderAmount}打${parValue}折优惠券`;
      }
    } else {
      if (orderAmountType === 1) {
        if (this.AppSource == "harmonyOS") {
          text = `${parValue}元优惠券`;
        } else {
          text = `${parValue}元无门槛优惠券`;
        }
      } else {
        text = `满${orderAmount}减${parValue}优惠券`;
      }
    }

    return text;
  }
  goDetail(couponId: number, timeDisplay: string): void {
    this.$router.push(
      `/coupon-detail?couponId=${couponId}&time=${timeDisplay}`
    );
  }
  formDate(date: string): string {
    const dt = new Date(Number(date) * 1000);
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    const hh = (dt.getHours() + "").padStart(2, "0");
    const mm = (dt.getMinutes() + "").padStart(2, "0");
    // const ss = (dt.getSeconds() + "").padStart(2, "0");

    return `${y}.${m}.${d} ${hh}:${mm}`;
  }
}
